import "./style.css";
import diversificada from "../../assets/images/diversificada.png";
import inovacao from "../../assets/images/inovacao.png";
import colaboracao from "../../assets/images/colaboracao.png";
import search from "../../assets/images/search.png";
import SessionInfo from "../../components/SessionInfo";
import Balloon from "../../components/Balloon";

function Team() {
  const renderBox = (box) => {
    return (
      <div class="flex flex-col gap-2 team-box">
        <div className="m-auto">
          <img className="m-auto" alt={box.icon.alt} src={box.icon.file} />
        </div>
        <div class="font-bold text-3xl lg:whitespace-nowrap text-center">
          {box.title}
        </div>
        <div class="text-center text-2xl">{box.text}</div>
      </div>
    );
  };
  const boxes = [
    {
      size: "small",
      icon: { alt: "diversificada", file: diversificada },
      title: "Experiência Diversificada",
      text: "Nossa equipe reúne talentos com vasta experiência em diversos segmentos, enriquecendo nossa abordagem e conhecimento.",
    },
    {
      size: "medium",
      icon: { alt: "inovacao", file: inovacao },
      title: "Inovação e Criatividade",
      text: "Valorizamos a inovação e a criatividade, incentivando um ambiente propício para ideias originais e soluções inovadoras para nossos clientes.",
    },
    {
      size: "small",
      icon: { alt: "colaboracao", file: colaboracao },
      title: "Colaboração Efetiva",
      text: "Promovemos uma cultura de colaboração, com foco na integração de habilidades e perspectivas para alcançar os melhores resultados.",
    },
  ];
  return (
    <div className="w-full">
      <div className="w-full m-auto flex flex-col pt-16 max-w-screen-2xl px-4 lg:px-0">
        <SessionInfo
          title="Equipe dedicada"
          info="E especialista no mercado"
          color="black"
        />
        <div>
          <div class="w-full lg:m-auto py-10 px-4 lg:px-0">
            <div class="flex flex-col lg:flex-row gap-8 lg:gap-0">
              {boxes.map(renderBox)}
            </div>
          </div>

          <div>
            <Balloon
              icon={{ alt: "search", file: search }}
              text={
                "Descubra oportunidades excepcionais e dê o próximo passo na sua carreira. Confira nossas vagas!"
              }
              button={{
                text: "Saiba mais",
                link: "https://jobs.quickin.io/opah/jobs",
                className: "search-buttom",
                color: "purple",
                blank: true,
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Team;
