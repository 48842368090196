import "./style.css";
import BannerBottomProduct from "./bottom/product";
import BannerBottomSegment from "./bottom/segment";
import Banners from "./banners/carrosel";
import BannersCarreer from "./banners/carreer/carrosel";

function Banner({
  carrousel: { items },
  banners,
  elipses,
  end = "product",
  height = "h-[980px]",
}) {
  const banner = banners[0];

  const bannersRenders = [
    {
      name: "solution",
      component: Banners,
    },
    {
      name: "carreer",
      component: BannersCarreer,
    },
  ];

  const bottomRenders = [
    {
      name: "product",
      component: BannerBottomProduct,
    },
    {
      name: "segment",
      component: BannerBottomSegment,
    },
  ];
  const Component = bannersRenders.find((b) => b.name === banner)?.component;
  const Bottom = bottomRenders.find((b) => b.name === end)?.component;

  return (
    <>
      <div className="flex">{Component && <Component />}</div>

      <div className="w-full">
        <div className="w-full m-auto flex flex-col mt-12 lg:mt-32 max-w-screen-2xl">
          {Bottom && <Bottom items={items} />}
        </div>
      </div>
    </>
  );
}

export default Banner;
