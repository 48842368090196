import carreer from "../../../../assets/images/company_banner.png";
import Button from "../../../../components/Button";

const mail = "contato@talentyou.com.br";
const subject = "TalentYou%20-%20Banco%20de%20Talentos!";
const body =
  "Olá!%20Estou%20enviando%20meu%20currículo%20para%20o%20Banco%20de%20talentos%20da%20TalentYou!";
const mailto = `mailto:${mail}?Subject=${subject}&body=${body}`;

function BannerSolution() {
  return (
    <div className="lg:inline-flex items-center justify-center gap-16 text-white px-4 lg:px-0">
      <div className="w-[150px] mb-4 lg:mb-0 m-auto lg:w-[890px] object-cover">
        <img alt="Carreer" className="m-auto" src={carreer} />
      </div>

      <div class="flex flex-col gap-4 lg:gap-8">
        <div className="flex flex-col gap-1">
          <h1 className="lg:text-left text-right font-bold font-arimo text-2xl lg:text-8xl">
            Conheça um jeito novo de impulsionar a sua carreira
          </h1>
          <p className="text-right lg:text-left text-xl lg:text-subtitle font-arimo ">
            e a Talent You te ajuda a encontrá-los
          </p>
        </div>

        <div className="flex flex-col text-left gap-10">
          <p class="lg:text-2xl text-right lg:text-left font-arimo-medium">
            Conte com nossas soluções personalizadas, feitas para você avançar
            com confiança na sua jornada.
          </p>

          <div className="flex justify-start">
            <Button
              link={mailto}
              text="Para candidatos"
              className="w-full lg:w-[400px] lg:self-end whitespace-nowrap"
              color="purple"
              session={true}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default BannerSolution;
