import "./style.css";
import Button from "../../components/Button";

function Balloon({ backColor = "gray" }) {
  const mail = "contato@talentyou.com.br";
  const subject = "TalentYou%20-%20Banco%20de%20Talentos!";
  const body =
    "Olá!%20Estou%20enviando%20meu%20currículo%20para%20o%20Banco%20de%20talentos%20da%20TalentYou!";
  const mailto = `mailto:${mail}?Subject=${subject}&body=${body}`;

  const color = backColor === "pink" ? "bg-[#fc1ccb]" : "bg-gray-800";

  return (
    <div
      class={`flex flex-col ${color} px-4 py-4 max-w-7xl lg:flex-row lg:whitespace-nowrap m-auto justify-center items-center gap-4 text-center w-full lg:rounded-[100px]`}
    >
      <div class="balloon-employe-text">
        Não encontrou a vaga que estava esperando? Faça o upload do seu
        currículo e envie para nosso banco de talentos!
        <b>
          <a href={mailto}>banco de talentos!</a>
        </b>
      </div>
      <Button color="purple" text="Enviar meu CV" link={mailto} />
    </div>
  );
}

export default Balloon;
