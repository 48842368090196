import "./style.css";
import logoBig from "../../assets/images/logo-big.png";
import face from "../../assets/images/face.png";
import insta from "../../assets/images/insta.png";
import tiktok from "../../assets/images/tiktok.png";
import linkedin from "../../assets/images/linkedin.png";
import youtube from "../../assets/images/youtube.png";
import spotify from "../../assets/images/spotify.png";
import c from "../../assets/images/c.png";
import cPink from "../../assets/images/c-pink.png";
import { Link } from "react-scroll";

function Footer({ color = "purple" }) {
  const colorFooter = color === "purple" ? "text-pink" : "text-[#823abe]";
  return (
    <div className="w-full lg:px-16 m-auto mt-10 px-4  lg:mt-[115px]">
      <div class="flex flex-col gap-10 lg:gap-0 lg:flex-row justify-between items-center">
        <div class="flex flex-col justify-center items-center lg:flex-1 gap-6">
          <img className="w-[150px] lg:w-auto" alt="logoBig" src={logoBig} />

          <div class="flex gap-4 flex-wrap px-10 lg:px-0">
            <a
              href="https://www.facebook.com/people/TalentYou/61556361380018/"
              target="_blank"
              rel="noreferrer"
            >
              <img alt="face" src={face} />
            </a>
            <a
              href="https://www.instagram.com/talentyoudigital/"
              target="_blank"
              rel="noreferrer"
            >
              <img alt="insta" src={insta} />
            </a>
            <a
              href="https://www.tiktok.com/@talentyoudigital"
              target="_blank"
              rel="noreferrer"
            >
              <img alt="tiktok" src={tiktok} />
            </a>
            <a
              href="https://www.linkedin.com/company/talent-you-digital/?viewAsMember=true"
              target="_blank"
              rel="noreferrer"
            >
              <img alt="linkedin" src={linkedin} />
            </a>
            <a
              href="https://www.youtube.com/channel/UCiWrGiBOuBpBml_z6zY9gCw"
              target="_blank"
              rel="noreferrer"
            >
              <img alt="youtube" src={youtube} />
            </a>
            <a href="#">
              <img alt="spotify" src={spotify} />
            </a>
          </div>
        </div>
        <div class="flex flex-col gap-10 lg:gap-0 lg:flex-row mx-10">
          <div class="footer-links">
            <div class={`footer-title ${color}`}>Institucional</div>
            <div class="footer-itens">
              <Link
                to="whoWeAre"
                className="footer-link"
                smooth={true}
                offset={50}
                duration={500}
                activeClass="active"
              >
                Quem somos
              </Link>
              <Link
                to="valores"
                className="footer-link"
                smooth={true}
                offset={50}
                duration={500}
                activeClass="active"
              >
                Nosso valores
              </Link>
              <a
                class="footer-link"
                href="https://www.google.com/search?q=talent+you"
              >
                Talent You na Mídia
              </a>
              <a class="footer-link" href="/employe">
                Trabalhe conosco
              </a>
              <a class="footer-link" href="/">
                Mapa do site
              </a>
            </div>
          </div>
          <div class="footer-links">
            <div class="footer-title black">Vagas</div>
            <div class="footer-itens">
              <a
                class="footer-link"
                href="https://jobs.quickin.io/opah/jobs?title=front%20end"
                target="_blank"
                rel="noreferrer"
              >
                FrontEnd
              </a>
              <a
                class="footer-link"
                href="https://jobs.quickin.io/opah/jobs?title=backend"
                target="_blank"
                rel="noreferrer"
              >
                BackEnd
              </a>
              <a
                class="footer-link"
                href="https://jobs.quickin.io/opah/jobs?title=fullstack"
                target="_blank"
                rel="noreferrer"
              >
                FullStack
              </a>
              <a
                class="footer-link"
                href="https://jobs.quickin.io/opah/jobs?title=iot%20"
                target="_blank"
                rel="noreferrer"
              >
                IoT
              </a>
              <a
                class="footer-link"
                href="https://jobs.quickin.io/opah/jobs?title=scrum%20master"
                target="_blank"
                rel="noreferrer"
              >
                Scrum Master
              </a>
              <a
                class="footer-link"
                href="https://jobs.quickin.io/opah/jobs?title="
                target="_blank"
                rel="noreferrer"
              >
                Outras
              </a>
            </div>
          </div>
        </div>
      </div>

      <div class={`mega-footer ${colorFooter}  lg:flex ${color} `}>
        <div className="flex items-center justify-center gap-2">
          <span className="font-bold">Talent You</span>
          {color === "pink" && <img alt="c-pink" src={cPink} />}
          {color === "purple" && <img alt="c" src={c} />}
          <span className="mr-1">2024</span>
        </div>
        <span>Todos os direitos reservados.</span>
      </div>
    </div>
  );
}

export default Footer;
