import "./style.css";

// type = sequency | alternate
// textType = normal | bold
function ListItens({
  items,
  type = "sequency",
  colors = ["gray"],
  textType = "normal",
}) {
  return (
    <div className="grid mx-4 lg:px-0 lg:grid-cols-3 gap-y-4 gap-x-14 lg:justify-center items-start lg:max-w-full m-auto">
      {items.map((body, key) => {
        const isEven = colors.length > 1 ? key % 2 : 0;
        return (
          <div
            class={`list-items-box rounded py-2 px-4 text-white ${colors[isEven]}`}
          >
            {body}
          </div>
        );
      })}
    </div>
  );
}

export default ListItens;
