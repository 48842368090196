import SessionInfo from "../../../components/SessionInfo";
import Carrousel from "../../../components/Carrousel";

function BannerBottomProduct({ items }) {
  return (
    <>
      <div className="px-4 lg:px-0">
        <SessionInfo
          title="Produtos & Serviços"
          info="Conte com a Talent You para identificar e atrair os candidatos ideais para o seu negócio."
        />
      </div>
      <Carrousel items={items} />
    </>
  );
}

export default BannerBottomProduct;
