import "../../styles/style.css";
import Menu from "../../modules/Menu";
import Banner from "../../modules/Banner";
import Separator from "../../modules/Separator";
import WhoWeAre from "../../modules/WhoWeAre";
import Team from "../../modules/Team";
import Opah from "../../modules/Opah";
import InfoCarrousel from "../../modules/InfoCarrousel";
import Jobs from "../../modules/Jobs";
import Form from "../../modules/Form";
import Contact from "../../modules/Contact";
import Footer from "../../modules/Footer";

import drogariaRaia from "../../assets/images/clients/drogariaRaia.png";
import pagSeguro from "../../assets/images/clients/pagSeguro.png";
import volkswagen from "../../assets/images/clients/volkswagen.png";
import samsung from "../../assets/images/clients/samsung.png";
import submarino from "../../assets/images/clients/submarino.png";
import unimed from "../../assets/images/clients/unimed.png";
import ame from "../../assets/images/clients/ame.png";

import aws from "../../assets/images/tech/aws.png";
import node from "../../assets/images/tech/node.png";
import java from "../../assets/images/tech/java.png";
import python from "../../assets/images/tech/python.png";
import datadog from "../../assets/images/tech/datadog.png";
import ios from "../../assets/images/tech/ios.png";
import android from "../../assets/images/tech/android.png";

import icon_carrousel from "../../assets/images/icon_carrousel.png";
import icon_carrousel1 from "../../assets/images/icon_carrousel (1).png";
import icon_carrousel2 from "../../assets/images/icon_carrousel (2).png";
import icon_carrousel3 from "../../assets/images/icon_carrousel (3).png";

function Company() {
  const menuLinks = {
    items: [
      { link: "/", text: "Inicio" },
      { link: "whoWeAre", text: "Quem somos?", session: true },
      { link: "jobs", text: "Vagas", session: true },
      { link: "contact", text: "Contato", session: true },
    ],
    buttons: [
      { link: "form", text: "Fale conosco", color: "orange", session: true },
    ],
  };
  const bannerInfos = {
    elipses: {
      size: "small",
      positions: ["first", "second", "third"],
      colors: ["orange", "purple", "orange"],
    },
    banners: ["solution"],
    carrousel: {
      items: [
        {
          color: "orange",
          icon: { file: icon_carrousel, alt: "icon_carrousel" },
          info: "Plataforma de recrutamento integrado",
        },
        {
          color: "orange",
          icon: { file: icon_carrousel1, alt: "icon_carrousel1" },
          info: "Consultoria estratégica em recrutamento",
        },
        {
          color: "orange",
          icon: { file: icon_carrousel2, alt: "icon_carrousel2" },
          info: "Relatório e dashboard das posiçoes e contratações",
        },
        {
          color: "orange",
          icon: { file: icon_carrousel3, alt: "icon_carrousel3" },
          info: "Employee brand personalizado",
        },
      ],
    },
  };
  const separator = {
    icon: "idea",
    text: (
      <>
        Conte com a <b>Talent You</b> para identificar e atrair os candidatos
        ideais para o seu negócio.
      </>
    ),
    button: {
      session: true,
      color: "purple",
      text: "Fale conosco",
      link: "form",
    },
  };

  const clients = {
    title: <b>Alguns dos nossos clientes</b>,
    info: "Explore o sucesso de nossas parcerias! Conheça nossos clientes e descubra como estamos transformando negócios juntos",
    footer: false,
    infos: [
      {
        icon: { alt: "drogariaRaia", file: drogariaRaia },
        title: "RaiaDrogasil",
      },
      {
        icon: { alt: "pagSeguro", file: pagSeguro },
        title: "PagSeguro",
      },
      {
        icon: { alt: "volkswagen", file: volkswagen },
        title: "Volkswagen",
      },
      {
        icon: { alt: "samsung", file: samsung },
        title: "Samsung",
      },
      {
        icon: { alt: "submarino", file: submarino },
        title: "Submarino",
      },
      {
        icon: { alt: "unimed", file: unimed },
        title: "unimed Seguros",
      },
      {
        icon: { alt: "ame", file: ame },
        title: "Ame",
      },
    ],
  };

  const techs = {
    title: <i>Tecnologias</i>,
    info: "A inovação por trás do nosso trabalho! Explore as tecnologias que impulsionam nossas soluções e transformam o cenário empresarial.",
    footer: true,
    infos: [
      {
        icon: { alt: "aws", file: aws },
        title: "AWS",
      },
      {
        icon: { alt: "node", file: node },
        title: "Node.JS",
      },
      {
        icon: { alt: "java", file: java },
        title: "Java",
      },
      {
        icon: { alt: "python", file: python },
        title: "Python",
      },
      {
        icon: { alt: "datadog", file: datadog },
        title: "Datadog",
      },
      {
        icon: { alt: "ios", file: ios },
        title: "iOS",
      },
      {
        icon: { alt: "android", file: android },
        title: "Android",
      },
    ],
  };

  const jobs = {
    elipses: {
      size: "small",
      positions: ["first-job", "second-job"],
      colors: ["purple", "orange"],
    },
    listItems: {},
  };
  return (
    <div className="bg-[#343434] overflow-hidden">
      <Menu items={menuLinks.items} buttons={menuLinks.buttons} />
      <Banner
        carrousel={bannerInfos.carrousel}
        banners={bannerInfos.banners}
        elipses={bannerInfos.elipses}
        end="product"
      />

      <div className="mt-10 lg:mt-0">
        <Separator
          icon={separator.icon}
          text={separator.text}
          button={separator.button}
        />
      </div>

      <div className="mt-60 lg:mt-0">
        <WhoWeAre height="h-[1080px] lg:h-[900px]" colorBase={null} />
      </div>

      <div className="bg-white">
        <Team />
      </div>

      <div className="bg-white bg-opah bg-no-repeat lg:bg-[115%] flex flex-col gap-20">
        <Opah />
        <div className="px-2 lg:px-0 flex flex-col lg:gap-10">
          <InfoCarrousel
            infos={clients.infos}
            title={clients.title}
            info={clients.info}
            footer={false}
          />
          <InfoCarrousel
            infos={techs.infos}
            title={techs.title}
            info={techs.info}
            footer={true}
          />
        </div>
      </div>

      <Jobs end listItems={jobs.listItems} elipses={jobs.elipses} />
      <div className="bg-white">
        <Form />
      </div>

      <div className="bg-white pb-20 px-2 lg:px-0">
        <Contact />
      </div>
      <Footer />
    </div>
  );
}

export default Company;
