import "./style.css";

function SessionInfo({ title, info, color, textInfo }) {
  const getColor = (color) => {
    switch (color) {
      case "purple":
        return "text-[#823abe]";
      case "black":
        return "text-black";
      default:
        return "text-white";
    }
  };

  return (
    <div className="w-full flex flex-col gap-2  text-left">
      <div
        class={`text-3xl lg:text-5xl font-bold  font-arimo-medium ${getColor(
          color
        )}`}
      >
        {title}
      </div>
      <p class={`font-arimo text-xl  ${getColor(textInfo || color)}`}>{info}</p>
    </div>
  );
}

export default SessionInfo;
