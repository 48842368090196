import "./style.css";
import backgound2 from "../../assets/images/background2.png";
import backgoundl from "../../assets/images/background-l.png";
import backgoundr from "../../assets/images/background-r.png";
import lock from "../../assets/images/lock.png";
import especialistas from "../../assets/images/fale-com-especialistas.png";
import { useState } from "react";
import { useEffect } from "react";
import ReactInputMask from "react-input-mask";
import { validate } from "react-email-validator";

function Form() {
  const [name, setName] = useState("");
  const [mail, setMail] = useState("");
  const [ddd, setDDD] = useState("");
  const [phone, setPhone] = useState("");
  const [company, setCompany] = useState("");
  const [job, setJob] = useState("");
  const [employes, setEmployes] = useState("");
  const [mailTo, setMailTo] = useState("");
  const [showMailValidate, setShowMailValidate] = useState(false);

  const FormRender = () => {
    return (
      <form method="post" class="form-data lg:rounded-xl">
        <div class="form-control">
          <label for="name">Nome</label>
          <input
            name="name"
            class="form-name"
            onChange={(e) => setName(e.target.value)}
            value={name}
            placeholder="Digite seu nome..."
          />
        </div>
        <div class={`form-control ${showMailValidate && "error"}`}>
          <label for="mail">Email corporativo</label>
          <input
            name="mail"
            class="form-mail"
            onChange={(e) => setMail(e.target.value)}
            value={mail}
            placeholder="Digite seu email..."
          />
          {showMailValidate && (
            <label class="form-mail-error">Email inválido</label>
          )}
        </div>
        <div class="form-control">
          <label for="phone">Telefone</label>
          <div class="form-control-phone">
            <ReactInputMask
              name="pronePre"
              className="form-phonePre"
              onChange={(e) => setDDD(e.target.value)}
              value={ddd}
              mask={"99"}
              placeholder="99"
            />
            {/* <input name="phonePre" class="form-phonePre" onChange={(e) => setDDD(e.target.value)} value={ddd} placeholder="DDD" /> */}
            <ReactInputMask
              name="phone"
              class="form-phone"
              onChange={(e) => setPhone(e.target.value)}
              value={phone}
              mask={"99999-9999"}
              maskChar={"-"}
              placeholder="99999-9999"
            />
            {/* <input name="phone" class="form-phone" onChange={(e) => setPhone(e.target.value)} value={phone} placeholder="Número" /> */}
          </div>
        </div>
        <div class="form-control">
          <label for="company">Empresa</label>
          <input
            name="company"
            class="form-company"
            onChange={(e) => setCompany(e.target.value)}
            value={company}
            placeholder="Digite o nome da sua empresa..."
          />
        </div>
        <div class="form-control">
          <div class="flex flex-col gap-8 lg:flex-row items-center">
            <div class="mt-0 lg:mt-6">
              <label for="office">Seu cargo atual?</label>
              <select
                name="office"
                class="form-office"
                onChange={(e) => setJob(e.target.value)}
                value={job}
              >
                <option value="diretor">Diretor</option>
                <option value="superintendente">Superintedende</option>
                <option value="gerente">Gerente</option>
                <option value="supervisor">Supervisor</option>
                <option value="outros">Outros</option>
              </select>
            </div>
            <div class="">
              <label for="employees">
                Quantos funcionários sua empresa possui?
              </label>
              <select
                name="employees"
                class="form-employees"
                onChange={(e) => setEmployes(e.target.value)}
                value={employes}
              >
                <option value="10">10</option>
                <option value="10">50</option>
                <option value="10">100</option>
                <option value="-1">Mais de 100</option>
              </select>
            </div>
          </div>
        </div>
        <div class="lock">
          <div class="lock-image">
            <img alt="lock" src={lock} />
          </div>
          <div class="lock-text">Cadastro seguro</div>
        </div>
        <a
          class={`form-buttom ${!mailTo && "disable"}`}
          href={mailTo || undefined}
        >
          Fale conosco
        </a>
      </form>
    );
  };

  useEffect(() => {
    if (
      name &&
      mail &&
      ddd &&
      phone &&
      company &&
      job &&
      employes &&
      !showMailValidate
    ) {
      const email = "contato@talentyou.com.br";
      const subject = `TalentYou%20-%20Contato%20empresarial%20-%20${name}`;
      const body = `Olá! %0DEstou entrando em contato para minha empresa. %0DSegue meus dados de contato para retorno:  %0D%0D
            Nome: ${name} %0D
            Email: ${mail} %0D
            Telefone: (${ddd}) ${phone}  %0D
            Empresa: ${company} %0D
            Meu cargo: ${job} %0D
            Quantidade de Funcionários: ${employes} %0D%0D%0DAguardo retorno!`;
      setMailTo(`mailto:${email}?Subject=${subject}&Body=${body}`);
    }
  }, [name, mail, ddd, phone, company, job, employes, showMailValidate]);

  useEffect(() => {
    if (!mail) return setShowMailValidate(false);

    setShowMailValidate(!validate(mail));
  }, [mail]);

  return (
    <>
      <div className="lg:hidden w-full">
        <div class="m-8 pt-8">
          <img className="w-full" alt="especialistas" src={especialistas} />
        </div>
        <div class="px-4 my-12">
          <div className="text-lg">
            Como especialistas em recrutamento, compreendemos a importância de
            construir uma equipe talentosa e alinhada com os objetivos
            estratégicos da sua empresa.
            <br />
            <br />
            Se está em busca de profissionais qualificados que impulsionem o
            sucesso do seu negócio, estamos à disposição para oferecer soluções
            personalizadas de recrutamento.
            <br />
            <br />
            Vamos trabalhar juntos para encontrar os colaboradores ideais e
            fortalecer sua equipe.
          </div>
        </div>
        <div>{FormRender()}</div>
      </div>
      <div className="hidden lg:block">
        <div class="background-form">
          <div class="back-from">
            <img alt="backgoundl" src={backgoundl} class="back-from-l" />
            <img alt="backgoundr" src={backgoundr} class="back-from-r" />
          </div>
          <img alt="backgound2" src={backgound2} class="back-colors" />
        </div>
        <div id="form" class="form">
          <div class="form-purple">{FormRender()}</div>
          <div class="form-body">
            <div class="form-title">
              <div class="form-image">
                <img alt="especialistas" src={especialistas} />
              </div>
            </div>
            <div class="form-text">
              Como especialistas em recrutamento, compreendemos a importância de
              construir uma equipe talentosa e alinhada com os objetivos
              estratégicos da sua empresa.
              <br />
              <br />
              Se está em busca de profissionais qualificados que impulsionem o
              sucesso do seu negócio, estamos à disposição para oferecer
              soluções personalizadas de recrutamento.
              <br />
              <br />
              Vamos trabalhar juntos para encontrar os colaboradores ideais e
              fortalecer sua equipe.
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Form;
