import React from 'react';
import { createBrowserRouter } from 'react-router-dom';
import './styles/index.css';

import Home from "./pages/Home";
import Company from "./pages/Company";
import Employe from "./pages/Employe";

const routes = createBrowserRouter([
    { path: "/", element: <Home /> },
    { path: "/company", element: <Company /> },
    { path: "/employe", element: <Employe /> }
]);

export default routes;