import SessionInfo from "../../../components/SessionInfo";
import ListItens from "../../../components/ListItens";

function BannerBottomSegment({ items }) {
  const list = [
    "Vendas & Comercial",
    "Agronegócio",
    "Saúde & Bem-Estar",
    "Operações & Supply Chain",
    "Engenharia & Manutençao",
    "Bancos & FinTechs",
    "ADM",
    "Recursos Humanos",
    "Marketing",
  ];
  return (
    <div className="w-full flex flex-col gap-8">
      <SessionInfo
        title="Segmentos"
        info={
          <>
            Conte com a <b>Talent You</b> para encontrar a vaga certa para você.
          </>
        }
      />

      <div className="w-full">
        <ListItens
          items={list}
          type="alternate"
          colors={["purple", "pink"]}
          textType="bold"
        />
      </div>
    </div>
  );
}

export default BannerBottomSegment;
