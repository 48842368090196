import "./style.css";

import opah_info from "../../assets/images/opah-info.png";
import SessionInfo from "../../components/SessionInfo";

function Opah() {
  return (
    <div className="w-full">
      <div className="w-full m-auto flex flex-col pt-16 max-w-screen-2xl px-4 lg:px-0">
        <div class="opah-header">
          <SessionInfo
            title={
              <>
                <b>Talent You</b> é uma marca <i>OPAH IT</i>
              </>
            }
            info="A OPAH IT é especialista em criação de soluções digitais e fã da velocidade e eficiência na entrega dos projetos. Trabalhando sempre com profissionais capacitados e apaixonados por tecnologia e inovação."
            color="black"
          />
        </div>
        <div class="opah-body">
          <img alt="opah_info" src={opah_info} />
        </div>
      </div>
    </div>
  );
}

export default Opah;
