import "./style.css";
import company from "../../assets/images/company.png";
import employe from "../../assets/images/employe.png";
import idea from "../../assets/images/idea.png";
import Balloon from "../../components/Balloon";
import Button from "../../components/Button";

function PagesBox() {
  const renderBox = (box) => {
    return (
      <div class="page-box px-6 lg:px-0">
        <img alt={box.icon.alt} src={box.icon.file} />

        <div class={`page-title-box ${box.color}`}>{box.title}</div>
        <div class="page-text">{box.text}</div>

        <div class="page-box-footer">
          <Button
            link={box.button.link}
            text={box.button.text}
            color={box.color}
          />
        </div>
      </div>
    );
  };
  const boxes = [
    {
      color: "orange",
      icon: { alt: "company", file: company },
      title: "Encontre os Talentos Ideais para sua Equipe",
      text: (
        <>
          Conecte-se conosco e descubra soluções personalizadas de recrutamento,
          adaptadas às necessidades únicas da sua empresa.
          <br />
          <br />
          <br />
          Juntos, vamos construir o futuro da sua equipe!
        </>
      ),
      button: { text: "Para empresas", link: "/company/#" },
    },
    {
      color: "purple",
      icon: { alt: "employe", file: employe },
      title: "Descubra Oportunidades Feitas para Você",
      text: (
        <>
          Explore nosso universo de vagas e conecte-se com oportunidades que
          combinam com seu talento e ambições.
          <br />
          <br />
          Seja o protagonista da sua jornada profissional. Estamos aqui para
          guiá-lo!
        </>
      ),
      button: { text: "Para candidatos", link: "/employe/#" },
    },
  ];
  return (
    <div class="py-20 flex flex-col px-4 lg:px-0">
      <div class="flex flex-col gap-8 lg:gap-0 px-4 lg:flex-row items-center justify-center">
        {boxes.map(renderBox)}
      </div>

      <div class="pages-footer">
        <Balloon
          icon={{ alt: "idea", file: idea }}
          text={
            <>
              Conte com a <b>Talent You</b> para identificar e atrair os
              candidatos ideais para o seu negócio.
            </>
          }
          button={{
            text: "Saiba mais",
            link: "/company/#",
            className: "pages-footer-button",
            color: "orange",
          }}
        />
      </div>
    </div>
  );
}

export default PagesBox;
