import "./style.css";

import searchJobs from "./../../assets/images/searchJobs.png";

function TalkRob({ titleColor = "black" }) {
  return (
    <div className="flex flex-col pt-32 bg-opah bg-no-repeat bg-[115%]">
      <div
        class="flex max-w-7xl m-auto flex-col lg:flex-row items-center"
        name="talkRob"
      >
        <div class="px-4 lg:px-0">
          <div class={`text-purple-500 text-4xl font-bold`}>
            Chega de falar com robôs!
          </div>
          <div class="talkRob-text">
            Trabalhamos com tecnologia, sim! Mas temos uma equipe humanizada e
            focada em você, nosso <b>Talent</b>, para tirar todas as suas
            dúvidas e te auxiliar durante o processo.
          </div>
        </div>
        <div class="px-4 lg:px-0">
          <img className="w-full" alt="searchJobs" src={searchJobs} />
        </div>
      </div>
    </div>
  );
}

export default TalkRob;
