import "./style.css";

import diamond from "../../assets/images/diamond.png";
import missao from "../../assets/images/missao.png";
import valores from "../../assets/images/valores.png";
import visao from "../../assets/images/visao.png";
import whoWeAre from "../../assets/images/whoWeAre.png";
import whoWeArePink from "../../assets/images/whoWeArePink.png";
import Balloon from "../../components/Balloon/employe";
import SessionInfo from "../../components/SessionInfo";

function WhoWeAre({
  elipses = true,
  colorBase = "orangePurple",
  footer = "missaoVisaoValores",
  height = "h-[900px]",
}) {
  const renderBox = (box) => {
    return (
      <div class="w-full lg:w-1/3 flex flex-col justify-center items-center lg:px-8 gap-16 lg:gap-2">
        <div>
          <img alt={box.icon.alt} src={box.icon.file} />
        </div>
        <div className="flex flex-col gap-8 lg:gap-2">
          <div class="text-3xl font-bold text-white text-center">
            {box.title}
          </div>
          <div class="text-white text-center">{box.text}</div>
        </div>
      </div>
    );
  };
  const boxes = [
    {
      icon: { alt: "missao", file: missao },
      title: "Missão",
      text: "Facilitar o encontro entre clientes e talentos excepcionais.",
    },
    {
      icon: { alt: "visao", file: visao },
      title: "Visão",
      text: "Ser reconhecida como principal solução de recrutamento com a melhor tecnologia disponível no mercado de R&S.",
    },
    {
      icon: { alt: "valores", file: valores },
      title: "Valores",
      text: "Parceria - Segurança - Inovação Comprometimento - Facilitação Solução",
    },
  ];

  const renderMissao = () => {
    const text = `Somos especialistas em identificar o melhor candidato para sua necessidade, avaliando soft e hard skills cruciais para o melhor andamento do seu negócio.`;

    return (
      <div className="mb-16 px-[30px] lg:px-0">
        <div className="w-full flex flex-col lg:flex-row my-16 box-diamon rounded-lg lg:rounded-[100px] p-4 lg:px-8 lg:py-4 gap-6 items-center">
          <div>
            <img alt="diamond" src={diamond} />
          </div>
          <div class="text-xl lg:text-2xl text-center lg:text-left font-semibold text-white p-2">
            {text}
          </div>
        </div>
        <div>
          <div class="mt-12 flex flex-col lg:flex-row w-auto gap-16 lg:gap-0">
            {boxes.map(renderBox)}
          </div>
        </div>
      </div>
    );
  };
  const renderGrayBalloon = () => (
    <div>
      <br />
      <br />
      <Balloon />
    </div>
  );

  const renderFooter = () => {
    if (footer === "missaoVisaoValores") return renderMissao();
    if (footer === "grayBalloon") return renderGrayBalloon();

    return <></>;
  };
  let color = "black";
  if (elipses) color = "white";

  const colorTitle =
    colorBase === "orangePurple" ? "text-primary" : "text-pink";

  const colorText = colorBase ? "text-black" : "text-white";

  return (
    <div className="w-full">
      <div className="w-full m-auto flex flex-col mt-16 max-w-screen-2xl px-4 lg:px-0">
        <SessionInfo
          title="Quem somos?"
          info="Conte com a Talent You para identificar e atrair os candidatos ideais para o seu negócio."
          color={color}
        />

        <div class="flex flex-col lg:flex-row mt-8 lg:mt-16 m-auto items-center lg:w-[1430px] gap-16">
          <div className="lg:w-[677px] w-full">
            <img
              alt="whoWeAre"
              className="w-[200px] lg:w-auto m-auto lg:m-0"
              src={colorBase === "orangePurple" ? whoWeAre : whoWeArePink}
            />
          </div>

          <div class="">
            <div
              class={`text-3xl text-left lg:text-6xl font-semibold ${colorTitle} mb-6`}
            >
              Hunting personalizado de alto desempenho
            </div>
            <div class={`text-2xl ${colorText}`}>
              Uma empresa brasileira com atuação estratégica no recrutamento e
              seleção para empresas do mercado nacional. Integrando necessidades
              e soluções de alto desempenho para empresas que buscam eficiência
              e excelência na identificação e seleção de talentos.
              <br />
              <br />
              Com uma abordagem humanizada e centrada no recrutador, a{" "}
              <span className="font-bold">Talent You</span> se destaca através
              da tecnologia de ponta nos processos internos, proporcionando
              resultados excepcionais para empregadores e candidatos.
            </div>
          </div>
        </div>
      </div>
      <div class="max-w-screen-2xl m-auto lg:mt-16" name="valores">
        {renderFooter()}
      </div>
    </div>
  );
}

export default WhoWeAre;
