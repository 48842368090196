import "./style.css";
import idea from "../../assets/images/idea.png";
import search from "../../assets/images/search.png";
import search3 from "../../assets/images/search3.png";
import Button from "../../components/Button";

function Separator({ icon, text, button, notback }) {
  const icons = [
    { alt: "idea", file: idea },
    { alt: "search", file: search },
    { alt: "search3", file: search3 },
  ];
  const image = icons.find((item) => item.alt === icon);
  const background = notback ? "back" : "bg-white";
  const textColor = notback ? "text-white" : "text-black";
  return (
    <div className={`w-full px-4 ${background}`}>
      <div className="w-full m-auto max-w-screen-2xl">
        <div
          class={`w-full flex flex-col items-center justify-center text-center lg:flex-row m-auto py-4 gap-2 lg:gap-10  ${textColor}`}
        >
          {image && <img width={48} alt={image.alt} src={image.file} />}
          <div class={`text-lg`}>{text}</div>

          <Button
            link={button.link}
            text={button.text}
            color={button.color}
            session={button.session}
            className="w-full lg:w-auto  px-4"
          />
        </div>
      </div>
    </div>
  );
}

export default Separator;
