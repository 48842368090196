import employer from "../../../../assets/images/carreer.png";
import Button from "../../../../components/Button";

function BannerCarreer01() {
  return (
    <div className="lg:inline-flex items-center justify-center gap-16 text-white px-4 lg:px-28">
      <div>
        <img
          width={861}
          alt="employers"
          className="m-auto lg:m-0"
          src={employer}
        />
      </div>

      <div class="flex flex-col gap-4 lg:gap-8">
        <div className="flex flex-col gap-1">
          <h1 className="lg:text-left text-right font-bold font-arimo text-3xl lg:text-7xl">
            Sua carreira merece o melhor!
          </h1>
        </div>

        <div className="flex flex-col text-left gap-10">
          <p class="lg:text-2xl text-right lg:text-left font-arimo-medium">
            Conte com a <span className="font-bold">Talent You</span> para
            encontrar as vagas do mercado feitas para você!
          </p>

          <div className="flex justify-start">
            <Button
              link="https://jobs.quickin.io/opah/jobs"
              blank={true}
              text="Ver nossas vagas"
              className="w-full lg:w-[400px] lg:self-end whitespace-nowrap"
              color="purple"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default BannerCarreer01;
