import "./style.css";
import search2 from "../../assets/images/search2.png";
import SessionInfo from "../../components/SessionInfo";
import ListItens from "../../components/ListItens/index";
import Balloon from "../../components/Balloon";
import BalloonEmploye from "../../components/Balloon/employe";

function Jobs({ listItems, end, employe, elipses }) {
  const jobsList = [
    "Engenheiro de Suprimentos",
    "Coordenador de Logística",
    "Especialista de FP&A (Financial Planning and Analysis)",
    "Coordenador de Tesouraria",
    "Desenvolvedor Back-End Java Sênior",
    "Desenvolvedor .Net Sênior",
    "Desenvolvedor Backend Java Sr",
    "CMO - Chief Marketing Officer",
    "Desenvolvedor Full Stack Sênior",
  ];
  const mail = "contato@talentyou.com.br";
  const subject = "TalentYou%20-%20Banco%20de%20Talentos!";
  const body =
    "Olá!%20Estou%20enviando%20meu%20currículo%20para%20o%20Banco%20de%20talentos%20da%20TalentYou!";
  const mailto = `mailto:${mail}?Subject=${subject}&body=${body}`;

  const renderEmploye = () => (
    <>
      <BalloonEmploye backColor="pink" />
    </>
  );

  const renderCompany = () => (
    <div className="w-full m-auto">
      <Balloon
        icon={{ alt: "search2", file: search2 }}
        text="Descubra oportunidades excepcionais e dê o próximo passo na sua carreira. Confira nossas vagas!"
        button={{
          text: "Nossas Vagas",
          link: "https://jobs.quickin.io/opah/jobs",
          className: "jobs-buttom",
          color: "purple",
          blank: true,
        }}
        noBorder={true}
        textColor={"white"}
      />

      {end && (
        <div class="jobs-end mt-4">
          Não encontrou a vaga que estava esperando? Envie o seu currículo para
          nosso{" "}
          <b>
            <a href={mailto}>banco de talentos!</a>
          </b>
        </div>
      )}
    </div>
  );
  return (
    <div className="w-full mb-16">
      <div className="w-full m-auto flex flex-col pt-16 max-w-screen-2xl px-4 lg:px-0">
        <div id="jobs">
          <div class="mt-8 px-2">
            <SessionInfo
              title="Vagas"
              info="Construa sua jornada profissional conosco! Explore nossas oportunidades e encontre a vaga perfeita para impulsionar sua carreira."
            />
          </div>
          <div className="my-8">
            <ListItens
              items={jobsList}
              colors={listItems.colors}
              textType={listItems.textType}
            />
          </div>

          {!employe && renderCompany()}
          {employe && renderEmploye()}
        </div>
      </div>
    </div>
  );
}

export default Jobs;
