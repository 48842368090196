import "./style.css";

function Carrousel({ items }) {
  const renderItem = (item) => {
    return (
      <div
        class={`w-full rounded-lg flex p-4 gap-2 justify-center items-center lg:flex-col ${item.color}`}
      >
        <img width={80} height={80} alt={item.icon.alt} src={item.icon.file} />
        <div class="text-white text-2xl font-bold text-center">{item.info}</div>
      </div>
    );
  };
  return (
    <div class="grid grid-cols-1 px-4 lg:px-0 lg:grid-cols-4 gap-12 my-16">
      {items.map(renderItem)}
    </div>
  );
}

export default Carrousel;
