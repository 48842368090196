import "./style.css";
import location from "../../assets/images/location.png";
import phone from "../../assets/images/phone.png";
import mail from "../../assets/images/mail.png";
import contact from "../../assets/images/contact.png";
import SessionInfo from "../../components/SessionInfo";

function Contact() {
  const renderInfoBox = (info) => {
    return (
      <div class="w-full flex items-start justify-center gap-2 lg:gap-8">
        <div class="w-[49px] mt-4">
          <img alt={info.icon.alt} src={info.icon.file} />
        </div>
        <div class="flex-1 ">
          <div class="info-box-title">{info.title}</div>
          <div class="info-box-text">{info.text}</div>
        </div>
      </div>
    );
  };
  const infos = [
    {
      icon: { alt: "location", file: location },
      title: "Endereço",
      text: (
        <>
          Av. das Nações Unidas. 12.551 - 25° Andar
          <br />
          Brooklin Novo, São Paulo - SP, 04578-903
        </>
      ),
    },
    {
      icon: { alt: "phone", file: phone },
      title: "Telefone",
      text: "(11) 96320-4619",
    },
    {
      icon: { alt: "mail", file: mail },
      title: "Email",
      text: "contato@talentyou.com.br",
    },
  ];
  return (
    <div className="w-full mb-16">
      <div className="w-full m-auto flex flex-col pt-16 max-w-screen-2xl px-4 lg:px-0">
        <div id="contact" name="contact">
          <div class="contact-header">
            <SessionInfo
              title="Contato"
              info="Estamos prontos para ouvir você! Entre em contato e descubra como podemos colaborar para alcançar seus objetivos."
              color="purple"
              textInfo="black"
            />
          </div>
          <div class="flex flex-col-reverse gap-20 lg:gap-0 lg:flex-row items-center justify-center max-w-7xl m-auto">
            <div class="w-full flex flex-col px-8 gap-6">
              {infos.map(renderInfoBox)}
            </div>
            <div class="contact-image">
              <img alt="contact" src={contact} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contact;
