import Button from "../../components/Button";
import logo from "../../assets/images/logo.png";
import "./style.css";
import { Link } from "react-scroll";
import { RxHamburgerMenu } from "react-icons/rx";
import { AiOutlineClose } from "react-icons/ai";
import { useState } from "react";

const Drawer = ({ isOpen, onClose, items = [], buttons = [] }) => {
  return (
    <div
      className={`text-white fixed inset-y-0 right-0 w-full md:w-64 gray shadow-xl z-40 transform transition duration-300 ease-in-out ${
        isOpen ? "translate-x-32" : "translate-x-full"
      }`}
    >
      <div className="p-4">
        <button onClick={onClose}>
          <AiOutlineClose />
        </button>
      </div>

      <div className="px-4 w-fit flex flex-col gap-2">
        {items.map((item, key) => {
          if (!item.session) {
            return (
              <a key={key} href={item.link}>
                {item.text}
              </a>
            );
          }

          return (
            <Link
              to={item.link}
              className="menu-link"
              smooth={true}
              offset={50}
              duration={500}
              activeClass="active"
              onClick={onClose}
            >
              {item.text}
            </Link>
          );
        })}

        {buttons.map((item, key) => {
          return (
            <div
              key={key}
              data-hightlight={item.highlight}
              onClick={onClose}
              className={`
              data-[hightlight=true]:text-orange-500
                data-[hightlight=true]:rounded-lg

                `}
            >
              <a className="p-0 m-0" href={item.link}>
                {item.text}
              </a>
            </div>
          );
        })}
      </div>
    </div>
  );
};
function Menu({ center, home = "/#", items, buttons, pink = false }) {
  const [isOpen, setIsOpen] = useState(false);
  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <header
        className={`px-4 text-white  py-1 lg:py-4 lg:px-8 flex justify-between items-center ${
          pink ? "pink" : "gray"
        } `}
      >
        <div>
          <a href={home}>
            <img className="w-32 md:w-48 lg:w-48" alt="logo" src={logo} />
          </a>
        </div>
        <div className="hidden lg:flex justify-center items-center">
          <div class="whitespace-nowrap font-bold flex gap-4 items-center">
            {items.map((item, i) => {
              if (!item.session)
                return (
                  <a class="menu-link" key={i} href={item.link}>
                    {item.text}
                  </a>
                );

              return (
                <Link
                  to={item.link}
                  className="menu-link"
                  smooth={true}
                  offset={50}
                  duration={500}
                  activeClass="active"
                >
                  {item.text}
                </Link>
              );
            })}

            {buttons.map((item, i) => {
              return (
                <Button
                  text={item.text}
                  key={i}
                  link={item.link}
                  className="menu-button"
                  color={item.color}
                  session={item.session}
                  blank={item.blank}
                />
              );
            })}
          </div>
        </div>

        <button
          onClick={toggleSidebar}
          className="text-white border p-2 rounded lg:hidden"
          type="button"
          data-drawer-target="drawer-navigation"
          data-drawer-show="drawer-navigation"
          aria-controls="drawer-navigation"
        >
          <RxHamburgerMenu />
        </button>
      </header>
      {isOpen && (
        <div
          className="fixed inset-0 bg-black opacity-50 z-40"
          onClick={toggleSidebar}
        ></div>
      )}
      <Drawer
        items={items}
        buttons={buttons}
        isOpen={isOpen}
        onClose={toggleSidebar}
      />
    </>
  );
}

export default Menu;
