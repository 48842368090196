import Banner from "../../modules/Banner";
import Contact from "../../modules/Contact";
import Footer from "../../modules/Footer";
import InfoCarrousel from "../../modules/InfoCarrousel";
import Jobs from "../../modules/Jobs";
import Menu from "../../modules/Menu";
import Opah from "../../modules/Opah";
import Separator from "../../modules/Separator";
import Team from "../../modules/Team";
import WhoWeAre from "../../modules/WhoWeAre";
import "../../styles/style.css";

import ame from "../../assets/images/clients/ame.png";
import drogariaRaia from "../../assets/images/clients/drogariaRaia.png";
import pagbank from "../../assets/images/clients/pagbank.png";
import samsung from "../../assets/images/clients/samsung.png";
import submarino from "../../assets/images/clients/submarino.png";
import unimed from "../../assets/images/clients/unimed.png";
import volkswagen from "../../assets/images/clients/volkswagen.png";

import android from "../../assets/images/tech/android.png";
import aws from "../../assets/images/tech/aws.png";
import datadog from "../../assets/images/tech/datadog.png";
import ios from "../../assets/images/tech/ios.png";
import java from "../../assets/images/tech/java.png";
import node from "../../assets/images/tech/node.png";
import python from "../../assets/images/tech/python.png";

import icon_carrousel1 from "../../assets/images/icon_carrousel (1).png";
import icon_carrousel2 from "../../assets/images/icon_carrousel (2).png";
import icon_carrousel3 from "../../assets/images/icon_carrousel (3).png";
import icon_carrousel from "../../assets/images/icon_carrousel.png";
import PagesBox from "../../modules/PagesBox";

function Home() {
  const menuLinks = {
    items: [
      { link: "whoWeAre", text: "Quem somos?", session: true },
      { link: "/employe/#", text: "Para conditatos" },
      { link: "/company/#", text: "Para empresas" },
      { link: "contact", text: "Contato", session: true },
    ],
    buttons: [
      { link: "#form", text: "Cadastro" },
      { link: "#form", text: "Acessar", highlight: true, color: "orange" },
    ],
  };
  const bannerInfos = {
    elipses: {
      size: "small",
      positions: ["first", "second", "third"],
      colors: ["orange", "purple", "orange"],
    },
    banners: ["solution"],
    carrousel: {
      items: [
        {
          color: "purple",
          icon: { file: icon_carrousel, alt: "icon_carrousel" },
          info: "Plataforma de recrutamento integrado",
        },
        {
          color: "purple",
          icon: { file: icon_carrousel1, alt: "icon_carrousel1" },
          info: "Consultoria estratégica em recrutamento",
        },
        {
          color: "purple",
          icon: { file: icon_carrousel2, alt: "icon_carrousel2" },
          info: "Relatório e dashboard das posiçoes e contratações",
        },
        {
          color: "purple",
          icon: { file: icon_carrousel3, alt: "icon_carrousel3" },
          info: "Employee brand personalizado",
        },
      ],
    },
  };
  const separator = {
    icon: "search",
    text: "Candidato! Descubra oportunidades excepcionais e dê o próximo passo na sua carreira. Confira nossas vagas!",
    button: {
      color: "orange",
      text: "Nossas Vagas",
      link: "https://jobs.quickin.io/opah/jobs",
    },
  };

  const clients = {
    title: <b>Alguns dos nossos clientes</b>,
    info: "Explore o sucesso de nossas parcerias! Conheça nossos clientes e descubra como estamos transformando negócios juntos",
    footer: false,
    infos: [
      {
        icon: { alt: "drogariaRaia", file: drogariaRaia },
        title: "RaiaDrogasil",
        className: "w-[150px]",
      },
      {
        icon: { alt: "pagSeguro", file: pagbank },
        title: "PagBank",
        className: "w-[150px]",
      },
      {
        icon: { alt: "volkswagen", file: volkswagen },
        title: "Volkswagen",
        className: "w-[80px]",
      },
      {
        icon: { alt: "samsung", file: samsung },
        title: "Samsung",
        className: "w-[150px]",
      },
      {
        icon: { alt: "submarino", file: submarino },
        title: "Submarino",
        className: "w-[150px]",
      },
      {
        icon: { alt: "unimed", file: unimed },
        title: "unimed Seguros",
        className: "w-[80px]",
      },
      {
        icon: { alt: "ame", file: ame },
        title: "Ame",
        className: "w-[150px]",
      },
    ],
  };

  const jobs = {
    elipses: {
      size: "small",
      positions: ["first-job", "second-job"],
      colors: ["purple", "orange"],
    },
    listItems: {},
  };

  const techs = {
    title: <i>Tecnologias</i>,
    info: "A inovação por trás do nosso trabalho! Explore as tecnologias que impulsionam nossas soluções e transformam o cenário empresarial.",
    footer: true,
    infos: [
      {
        icon: { alt: "aws", file: aws },
        title: "AWS",
      },
      {
        icon: { alt: "node", file: node },
        title: "Node.JS",
      },
      {
        icon: { alt: "java", file: java },
        title: "Java",
      },
      {
        icon: { alt: "python", file: python },
        title: "Python",
      },
      {
        icon: { alt: "datadog", file: datadog },
        title: "Datadog",
      },
      {
        icon: { alt: "ios", file: ios },
        title: "iOS",
      },
      {
        icon: { alt: "android", file: android },
        title: "Android",
      },
    ],
  };

  return (
    <div className="bg-[#343434] overflow-hidden">
      <Menu center={true} items={menuLinks.items} buttons={menuLinks.buttons} />

      <Banner
        carrousel={bannerInfos.carrousel}
        banners={bannerInfos.banners}
        elipses={bannerInfos.elipses}
        end="product"
      />

      <div className="mt-12 lg:mt-0">
        <Separator
          icon={separator.icon}
          text={separator.text}
          button={separator.button}
        />
      </div>

      <div className="mt-12 lg:mt-0">
        <WhoWeAre colorBase={null} />
      </div>

      <div className="mt-12 lg:mt-0 bg-white">
        <Team />
      </div>

      <div className="bg-white bg-opah bg-no-repeat lg:bg-[115%] flex flex-col lg:gap-20">
        <Opah />

        <div className="px-2 lg:px-0">
          <InfoCarrousel
            infos={clients.infos}
            title={clients.title}
            info={clients.info}
            footer={false}
          />
        </div>
      </div>

      <Jobs listItems={jobs.listItems} elipses={jobs.elipses} />

      <div className="bg-white">
        <PagesBox />
      </div>

      <div className="bg-white px-2 lg:px-0 pb-6 lg:pb-0">
        <Contact />
      </div>

      <Footer />
    </div>
  );
}

export default Home;
