import { Link } from 'react-scroll';
import './style.css';

function Button({ text, link, className, color, blank = false, session = false }) {
    if (!session) {
        // eslint-disable-next-line
        return <a class={`button ${color} ${className}`} href={link} target={blank ? "_blank" : "_self"}>{text}</a>;
    }

    return <Link 
        to={link} 
        className={`button ${color} ${className}`} 
        smooth={true} 
        offset={50}
        duration={500} 
        activeClass='active'
        target={blank ? "_blank" : "_self"}
        >
            {text}
        </Link>
}

export default Button;
