import "../../styles/style.css";
import Menu from "../../modules/Menu";
import Banner from "../../modules/Banner";
import InfoCarrousel from "../../modules/InfoCarrousel";
import Jobs from "../../modules/Jobs";
import Footer from "../../modules/Footer";

import drogariaRaia from "../../assets/images/clients/drogariaRaia.png";
import pagSeguro from "../../assets/images/clients/pagSeguro.png";
import volkswagen from "../../assets/images/clients/volkswagen.png";
import samsung from "../../assets/images/clients/samsung.png";
import submarino from "../../assets/images/clients/submarino.png";
import unimed from "../../assets/images/clients/unimed.png";
import ame from "../../assets/images/clients/ame.png";

import icon_carrousel from "../../assets/images/icon_carrousel.png";
import icon_carrousel1 from "../../assets/images/icon_carrousel (1).png";
import icon_carrousel2 from "../../assets/images/icon_carrousel (2).png";
import icon_carrousel3 from "../../assets/images/icon_carrousel (3).png";
import Separator from "../../modules/Separator";
import WhoWeAre from "../../modules/WhoWeAre";
import TalkRob from "../../modules/TalkRob";

function Employe() {
  const menuLinks = {
    items: [
      { link: "/", text: "Inicio" },
      { link: "whoWeAre", text: "Quem somos?", session: true },
      { link: "jobs", text: "Vagas", session: true },
    ],
    buttons: [
      {
        link: "https://jobs.quickin.io/opah/jobs",
        text: "Nossas vagas",
        color: "pink",
        blank: true,
      },
    ],
  };
  const bannerInfos = {
    elipses: {
      size: "medium",
      positions: ["first-employe", "second-employe", "third-employe"],
      colors: ["purple", "pink", "purple"],
    },
    banners: ["carreer"],
    carrousel: {
      items: [
        {
          color: "orange",
          icon: { file: icon_carrousel, alt: "icon_carrousel" },
          info: "Plataforma de recrutamento integrado",
        },
        {
          color: "orange",
          icon: { file: icon_carrousel1, alt: "icon_carrousel1" },
          info: "Consultoria estratégica em recrutamento",
        },
        {
          color: "orange",
          icon: { file: icon_carrousel2, alt: "icon_carrousel2" },
          info: "Relatório e dashboard das posiçoes e contratações",
        },
        {
          color: "orange",
          icon: { file: icon_carrousel3, alt: "icon_carrousel3" },
          info: "Employee brand personalizado",
        },
      ],
    },
  };
  const separator = {
    icon: "search3",
    text: "Descubra oportunidades excepcionais e dê o próximo passo na sua carreira. Confira nossas vagas!",

    button: {
      color: "pink",
      text: "Nossas Vagas",
      link: "https://jobs.quickin.io/opah/jobs",
    },
  };

  const jobs = {
    listItems: {
      colors: ["purple"],
      textType: "bold",
    },
    balloon: {},
  };

  const clients = {
    title: <b>Alguns dos nossos clientes</b>,
    info: "Explore o sucesso de nossas parcerias! Conheça nossos clientes e descubra como estamos transformando negócios juntos",
    footer: false,
    infos: [
      {
        icon: { alt: "drogariaRaia", file: drogariaRaia },
        title: "RaiaDrogasil",
      },
      {
        icon: { alt: "pagSeguro", file: pagSeguro },
        title: "PagSeguro",
      },
      {
        icon: { alt: "volkswagen", file: volkswagen },
        title: "Volkswagen",
      },
      {
        icon: { alt: "samsung", file: samsung },
        title: "Samsung",
      },
      {
        icon: { alt: "submarino", file: submarino },
        title: "Submarino",
      },
      {
        icon: { alt: "unimed", file: unimed },
        title: "unimed Seguros",
      },
      {
        icon: { alt: "ame", file: ame },
        title: "Ame",
      },
    ],
  };

  return (
    <div className="bg-[#343434] overflow-hidden">
      <Menu pink items={menuLinks.items} buttons={menuLinks.buttons} />
      <Banner
        carrousel={bannerInfos.carrousel}
        banners={bannerInfos.banners}
        elipses={bannerInfos.elipses}
        end="segment"
      />

      <div className="lg:mt-12">
        <Separator
          icon={separator.icon}
          text={separator.text}
          button={separator.button}
          notback
        />
      </div>

      <div className="mt-4">
        <Jobs listItems={jobs.listItems} employe />
      </div>

      <div className="bg-white pt-8">
        <WhoWeAre
          elipses={false}
          colorBase={"pinkPurple"}
          footer={"grayBalloon"}
        />
      </div>

      <div className="bg-white flex flex-col gap-20">
        <TalkRob titleColor="purple" />

        <div className="px-2 lg:px-0 flex flex-col lg:gap-10">
          <InfoCarrousel
            infos={clients.infos}
            title={clients.title}
            info={clients.info}
            footer={false}
          />
        </div>
      </div>
      <Footer color="pink" />
    </div>
  );
}

export default Employe;
