import "./style.css";
import Button from "../../components/Button";

function Balloon({
  icon,
  text,
  textColor = "black",
  button: { link, text: buttonText, className, color, blank, session },
  noBorder,
}) {
  const borderColor = noBorder ? "border-none" : "border border-zinc-100";
  const shadow = noBorder ? "shadow-none" : "shadow-md";

  const colorText = textColor === "black" ? "text-black" : "text-white";

  return (
    <div
      class={`m-auto flex flex-col justify-between 
        lg:flex-row gap-6 items-center  lg:w-[1400px] 
        py-6 
        text-center
        lg:text-left
        lg:px-6 rounded-[100px] ${shadow} ${borderColor}
        ${colorText}
        `}
    >
      <img alt={icon.alt} src={icon.file} />

      <div class={`text-2xl ${textColor}`}>{text}</div>
      <Button
        link={link}
        text={buttonText}
        className={className}
        color={color}
        blank={blank}
        session={session}
      />
    </div>
  );
}

export default Balloon;
