import "./style.css";
import SessionInfo from "../../components/SessionInfo";
import idea from "../../assets/images/idea.png";
import Balloon from "../../components/Balloon";

function InfoCarrousel({ infos, title, info, footer }) {
  const renderInfos = (info) => {
    return (
      <>
        <div class="flex flex-col gap-1 justify-center items-center">
          <div>
            <img
              className={info.className}
              alt={info.icon.alt}
              src={info.icon.file}
            />
          </div>
          <div class="infoCarrousel-title">{info.title}</div>
        </div>
      </>
    );
  };
  const renderFooter = () => {
    const text = (
      <>
        Conte com a <b>Talent You</b> para identificar e atrair os candidatos
        ideias para o seu negócio.
      </>
    );
    return (
      <div class="mb-10">
        <Balloon
          icon={{ alt: "idea", file: idea }}
          text={text}
          button={{
            text: "Fale conosco",
            link: "form",
            className: "search-buttom",
            color: "purple",
            session: true,
          }}
        />
      </div>
    );
  };
  return (
    <div className="w-full">
      <div className="w-full m-auto flex flex-col pt-16 max-w-screen-2xl px-4 lg:px-0">
        <SessionInfo title={title} info={info} color="black" />
      </div>

      <div class="flex flex-col justify-between items-center lg:flex-row p-10 gap-6 lg:gap-0">
        {infos.map(renderInfos)}
      </div>

      {footer ? renderFooter() : <></>}
    </div>
  );
}

export default InfoCarrousel;
