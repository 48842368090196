import solucaoReS from "../../../../assets/images/solucaoReS.png";
import Button from "../../../../components/Button";

function BannerSolution() {
  return (
    <div className="lg:inline-flex gap-16 text-white px-4 lg:px-0">
      <div className="w-full">
        <div className="flex flex-col-reverse lg:flex-row items-center lg:gap-16">
          <div className="flex flex-col gap-0">
            <h1 className="text-right font-bold font-arimo text-3xl lg:text-8xl">
              A solução de R&S
            </h1>
            <p className="lg:mt-[-15px] text-right text-2xl lg:text-subtitle font-arimo">
              que integra seu processo de ponta a ponta
            </p>

            <div className="flex flex-col gap-10 mt-8 lg:items-end">
              <p class="lg:w-[715px] lg:text-2xl text-right font-arimo">
                Somos movidos por entender pessoas e seu RH, criando soluções e
                integrações personalizadas.
              </p>

              <div className="flex justify-end">
                <Button
                  link="contact"
                  text="Saiba mais"
                  className="w-full lg:w-[450px] lg:self-end whitespace-nowrap"
                  color="orange"
                  session={true}
                />
              </div>
            </div>
          </div>

          <div className="w-[150px] mb-8 lg:mb-0 m-auto lg:w-[630px] object-cover">
            <img alt="solucaoReS" src={solucaoReS} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default BannerSolution;
