import employer from "../../../../assets/images/carreer_2.png";
import Button from "../../../../components/Button";

const mail = "contato@talentyou.com.br";
const subject = "TalentYou%20-%20Banco%20de%20Talentos!";
const body =
  "Olá!%20Estou%20enviando%20meu%20currículo%20para%20o%20Banco%20de%20talentos%20da%20TalentYou!";
const mailto = `mailto:${mail}?Subject=${subject}&body=${body}`;

function BannerCarreer02() {
  return (
    <div className="lg:w-[1500px] lg:m-auto">
      <div className="lg:inline-flex gap-16 text-white px-4 lg:px-0">
        <div className="w-full">
          <div className="flex flex-col-reverse lg:flex-row items-center lg:gap-16">
            <div className="flex flex-col gap-0">
              <h1 className="text-right font-bold font-arimo text-3xl lg:text-7xl">
                Você não precisa fazer cadastro
              </h1>

              <div className="flex flex-col gap-10 mt-8 lg:items-end">
                <p class="lg:w-[715px] lg:text-2xl text-right font-arimo">
                  Deixei o seu currículo conosco e entraremos em contato caso o
                  seu perfil faça sentido para nossas vagas. Garanta que seus
                  contatos estejam atualizados.
                </p>

                <div className="flex justify-end">
                  <Button
                    link={mailto}
                    text="Enviar meu currículo"
                    className="w-full lg:w-[450px] lg:self-end whitespace-nowrap"
                    color="purple"
                    session={true}
                  />
                </div>
              </div>
            </div>

            <img
              className="m-auto lg:!w-[550px]"
              alt="employer"
              src={employer}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default BannerCarreer02;
