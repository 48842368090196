import React from "react";
import { Carousel } from "react-responsive-carousel";
import BannerSolution from "./solution";
import BannerEmployer from "./employer";
import BannerCarreer from "./carreer";

function Banners() {
  return (
    <div className="w-full mt-16">
      <section className="w-full lg:px-20 ">
        <Carousel
          transitionTime={1300}
          interval={12000}
          infiniteLoop
          showArrows={false}
          showThumbs={false}
          showStatus={false}
          swipeable={false}
          renderIndicator={(onClickHandler, isSelected, index, label) => {
            return (
              <li
                className={`inline-block w-6 h-6 mx-2 rounded-full cursor-pointer ${
                  isSelected ? "bg-primary" : "bg-secondary"
                }`}
                onClick={onClickHandler}
                onKeyDown={onClickHandler}
                key={index}
                title={label}
                aria-label={`Go to slide ${index}`}
                role="button"
                tabIndex={0}
              />
            );
          }}
        >
          <div>
            <BannerSolution />
          </div>
          <div>
            <BannerEmployer />
          </div>
          <div>
            <BannerCarreer />
          </div>
        </Carousel>
      </section>
    </div>
  );
}

export default Banners;
