import employer from "../../../../assets/images/searchJobs.png";
import Button from "../../../../components/Button";

function BannerEmployer() {
  return (
    <div className="lg:inline-flex items-center justify-center gap-16 text-white px-4 lg:px-0">
      <div className="w-[150px] mb-4 lg:mb-0 m-auto lg:w-[770px] object-cover">
        <img alt="employers" className="m-auto lg:m-0" src={employer} />
      </div>

      <div class="flex flex-col gap-4 lg:gap-8">
        <div className="flex flex-col gap-1">
          <h1 className="lg:text-left text-right font-bold font-arimo text-3xl lg:text-8xl">
            Sua equipe precisa de ótimos candidatos
          </h1>
          <p className="lg:mt-[-15px] text-right lg:text-left text-2xl lg:text-subtitle font-arimo">
            e a Talent You te ajuda a encontrá-los
          </p>
        </div>

        <div className="flex flex-col text-left gap-10">
          <p class="lg:text-2xl text-right lg:text-left font-arimo-medium">
            Nosso foco é entender as pessoas, criando soluções personalizadas e{" "}
            <br /> integradas de forma mais humana
          </p>

          <div className="flex justify-start">
            <Button
              link="company"
              text="Para empresas"
              className="w-full lg:w-[400px] lg:self-end whitespace-nowrap"
              color="orange"
              session={true}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default BannerEmployer;
